import React from 'react'

export const quotes = [
  <>
    <p>
      {'"Success is not the key to happiness. Happiness is the key to success.'}
    </p>
    <p>
      {'If you love what you are doing, you will be successful."'}
    </p>
    <p>
      {'– Albert Schweitzer'}
    </p>
  </>,
  <>
    <p>
      {'"Don\'t wait. The time will never be just right."'}
    </p>
    <p>
      {'– Napoleon Hill'}
    </p>
  </>,
  <>
    <p>
      {'"Opportunities don\'t happen, you create them."'}
    </p>
    <p>
      {'– Chris Grosser'}
    </p>
  </>,
  <>
    <p>
      {'"Don\'t ask yourself what the world needs. Ask yourself what makes you come alive, and go do that.'}
    </p>
    <p>
      {'Because what the world needs is people who have come alive."'}
    </p>
    <p>
      {'– Howard Thurman'}
    </p>
  </>,
  <>
    <p>
      {'"Find a job you enjoy doing, and you will never have to work a day in your life."'}
    </p>
    <p>
      {'– Mark Twain'}
    </p>
  </>,
  <>
    <p>
      {'"Chase your passion, not your pension."'}
    </p>
    <p>
      {'– Denis Waitley'}
    </p>
  </>,
  <>
    <p>
      {'"Find a job you enjoy doing, and you will never work a day in your life."'}
    </p>
    <p>
      {'– Confucius'}
    </p>
  </>,
  <>
    <p>
      {'"Do what you love, and the money will follow."'}
    </p>
    <p>
      {'– Marsha Sinetar'}
    </p>
  </>,
  <>
    <p>
      {'"Don’t wait for the perfect moment. Take the moment and make it perfect."'}
    </p>
    <p>
      {'– Unknown'}
    </p>
  </>,
  <>
    <p>
      {'"Your time is limited, so don’t waste it living someone else’s life."'}
    </p>
    <p>
      {'– Steve Jobs'}
    </p>
  </>,
  <>
    <p>
      {'"The best way to predict the future is to create it."'}
    </p>
    <p>
      {'– Peter Drucker'}
    </p>
  </>,
  <>
    <p>
      {'"Success is the sum of small efforts, repeated day in and day out."'}
    </p>
    <p>
      {'– Robert Collier'}
    </p>
  </>,
  <>
    <p>
      {'"The journey of a thousand miles begins with one step."'}
    </p>
    <p>
      {'– Lao Tzu'}
    </p>
  </>,
  <>
    <p>
      {'"You are never too old to set another goal or to dream a new dream."'}
    </p>
    <p>
      {'– C.S. Lewis'}
    </p>
  </>,
  <>
    <p>
      {'"It’s never too late to be what you might have been."'}
    </p>
    <p>
      {'– George Eliot'}
    </p>
  </>,
  <>
    <p>
      {'"There are no limits to what you can accomplish'}
    </p>
    <p>
      {'except the limits you place on your own thinking."'}
    </p>
    <p>
      {'– Brian Tracy'}
    </p>
  </>,
  <>
    <p>
      {'"Don\'t just live for the weekends. Find a job that excites you,'}
    </p>
    <p>
      {'and every day will feel like a new adventure."'}
    </p>
    <p>
      {'– Unknown'}
    </p>
  </>,
  <>
    <p>
      {'"We can’t become what we need to be by remaining what we are."'}
    </p>
    <p>
      {'– Max De Pree'}
    </p>
  </>,
  <>
    <p>
      {'"Do what you love, and the universe will open doors for you where there were only walls."'}
    </p>
    <p>
      {'– Joseph Campbell'}
    </p>
  </>,
  <>
    <p>
      {'"The only way to do great work is to love what you do. If you haven\'t found'}
    </p>
    <p>
      {'it yet, keep looking. Don\'t settle."'}
    </p>
    <p>
      {'– Steve Jobs'}
    </p>
  </>
]
