import React, { createContext, ReactElement, useContext, useEffect, useRef, useState } from 'react'

type popper = {
    id: string
    visible: boolean
    buttonRef?: React.MutableRefObject<HTMLButtonElement | null>
    content?: ReactElement
    top: number
    left: number
}

export type PopperContext = {
    idPopperVisible: (_id: string) => boolean,
    setPopper: (
        _id: string,
        _buttonRef: React.MutableRefObject<HTMLButtonElement | null>,
        _content: ReactElement
    ) => void
    hidePopper: () => void
}

const popperContext: React.Context<PopperContext> = createContext<PopperContext>({
  idPopperVisible: (): boolean => false,
  setPopper: (): void => {},
  hidePopper: (): void => {},
})

type contextProviderProps = {
    children?: ReactElement | ReactElement[]
}

export const PopperProvider: React.FC<contextProviderProps> = ({ children }) => {
  const [popper, setPopperState] = useState<popper>()
  const ref = useRef<HTMLDivElement>(null)

  const setPopper = (
    id: string,
    buttonRef: React.MutableRefObject<HTMLButtonElement | null>,
    content: ReactElement
  ): void => {
    if (buttonRef.current) {
      let visible: boolean = true

      if (popper?.id === id && popper.visible) {
        visible = false
      }

      const buttonRect: DOMRect = buttonRef.current.getBoundingClientRect()

      setPopperState({
        id: id,
        visible: visible,
        buttonRef: buttonRef,
        content: content,
        top: buttonRect.bottom + window.scrollY,
        left: buttonRect.left + window.scrollX
      })
    }
  }

  const hidePopper = () => setPopperState({
    id: '',
    visible: false,
    top: 0,
    left: 0
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        popper?.buttonRef?.current &&
        !popper.buttonRef.current.contains(event.target as Node)
      ) {
        hidePopper()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [popper])

  return (
    <popperContext.Provider
      value={{
        idPopperVisible: (id: string): boolean => (popper ? popper.id === id && popper.visible : false),
        setPopper: setPopper,
        hidePopper: hidePopper
      }}
    >
      <>
        {children}

        {popper?.visible && (
          <div
            ref={ref}
            className="flex flex-col items-center overflow-hidden p-2 bg-white border-[1px] border-[#e4e0e1] rounded-[10px] absolute z-10"
            style={{
              top: popper.top,
              left: popper.left,
              boxShadow: '0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
            }}
          >
            {popper.content}
          </div>
        )}
      </>
    </popperContext.Provider>
  )
}

export const usePopper = (): PopperContext =>
  useContext(popperContext)
