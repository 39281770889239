import React, { ReactElement } from 'react'
import logo from '../media/Mercedes-Benz-logo-E47583B444-seeklogo.com.png'
import { homeRoute, signInRoute, signUpRoute } from './routes'

const Navigation = (): ReactElement => (
  <nav className="sticky top-0 z-[1] bg-white">
    <div className="bg-white border-b-[#e4e0e1] border-b-[1px] flex justify-between items-center px-4 lg:px-0 py-[0.55rem] max-w-[1000px] mx-auto">
      <a href={homeRoute}>
        <img
          className="w-[25px]"
          src={logo}
          loading="lazy"
          alt="Few Steps Closer logo"
        />
      </a>

      <div className="flex">
        <a
          href={signInRoute}
          className="transition flex justify-center items-center align-middle text-[12px] lg:text-[14px] underline"
        >
          {'Sign in'}
        </a>

        <div className="mr-2 lg:mr-4"/>

        <a
          href={signUpRoute}
          className="rounded-[10px] bg-[#0035ff] text-white text-[12px] lg:text-[14px] text-center hover:bg-[#0029c4] transition flex justify-center align-middle p-2"
        >
          {'Sign up'}
        </a>
      </div>
    </div>
  </nav>
)

export default Navigation
