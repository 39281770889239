const minPasswordCharactersLimit: number = 8
const maxPasswordCharactersLimit: number = 64

const emailRegExp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const passwordRegExp: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).+$/

export const constructValidateErrorMessage = (field: string, reason: string): string =>
  `"${field}" ${reason}`

export const validateIsEmpty = (value?: unknown): boolean =>
  !value

export const validateEmail = (str?: string): [boolean, string] => {
  if (validateIsEmpty(str)) {
    return [false, constructValidateErrorMessage('email',  'input cannot be empty')]
  }

  if (!emailRegExp.test(str ?? '')) {
    return [false, constructValidateErrorMessage('email',  'has to be a valid email')]
  }

  return [true, '']
}

export const validatePassword = (str?: string): [boolean, string] => {
  if (validateIsEmpty(str)) {
    return [false, constructValidateErrorMessage('password', 'input cannot be empty')]
  }

  if (str) {
    if (str.length < minPasswordCharactersLimit) {
      return [false, constructValidateErrorMessage('password', 'input cannot have less than 8 characters')]
    }

    if (str.length > maxPasswordCharactersLimit) {
      return [false, constructValidateErrorMessage('password', 'input cannot have more than 64 characters')]
    }
  }

  if (!passwordRegExp.test(str ?? '')) {
    return [false, constructValidateErrorMessage('password', "doesn't meet format conditions")]
  }

  return [true, '']
}
