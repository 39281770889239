import React, { ReactElement, useState } from 'react'
import { Course } from '../types/types'
import PropTypes from 'prop-types'
import CourseCard from './CourseCard'

type coursesProps = {
    values: Course[]
}

const Courses: React.FC<coursesProps> = ({ values }): ReactElement => {
  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState<number>(2)

  return (
    <div className="flex flex-col overflow-hidden p-2 gap-2 text-center bg-[#f5f5f5] without-scrollbar">
      {values.map((course, index) => (
        index < numberOfVisibleItems && <CourseCard key={index} value={course}/>
      ))}

      {
        numberOfVisibleItems < values.length && (
          <button
            className="p-[0.25rem] px-[0.5rem] m-auto bg-white border-[1px] border-[#e4e0e1] rounded-[8px] text-[12px] text-[#575757] duration-300 ease-in-out hover:border-[#0035ff]"
            onClick={(): void => setNumberOfVisibleItems((prev: number): number => prev + 2)}
          >
            {'More courses'}
          </button>
        )
      }
    </div>
  )
}

Courses.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      subscribers: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired
}

export default Courses
