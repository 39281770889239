import React, { ReactElement } from 'react'
import { Suggestion as TSuggestion, UpdateSuggestion } from '../types/types'
import classNames from 'classnames'
import Suggestion from './Suggestion'
import { suggestionDemandFieldStyles, suggestionMatchRatingColor } from './helpers'

type userSuggestionsProps = {
    values: TSuggestion[]
    selected?: TSuggestion
    setSelected: (_suggestion: TSuggestion) => void
    updateSelected: (_id: string, _update: UpdateSuggestion) => void
}

const UserSuggestions: React.FC<userSuggestionsProps> = ({
  values,
  selected,
  setSelected,
  updateSelected
}): ReactElement => {
  if (selected) {
    return (
      <Suggestion
        value={selected}
        update={updateSelected}
      />
    )
  }

  return (
    <div
      className="overflow-y-scroll w-full border-[1px] border-[#e4e0e1] rounded-[10px] without-scrollbar"
      style={{
        boxShadow: '0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
      }}
    >
      <table className="w-full border-collapse z-[1] bg-clip-padding">
        <thead className="border-b border-[#e4e0e1] bg-[#f8f8f8] sticky top-0">
          <tr>
            <th className="p-2 lg:p-4 border-r">
              <p className="text-left text-[10px] md:text-[12px] lg:text-[14px] font-[400]">{'Title'}</p>
            </th>
            <th className="p-2 lg:p-4 border-l border-r">
              <p className="text-left text-[10px] md:text-[12px] lg:text-[14px] font-[400]">{'Match rating'}</p>
            </th>
            <th className="p-2 lg:p-4 border-l border-r">
              <p className="text-left text-[10px] md:text-[12px] lg:text-[14px] font-[400]">{'Demand'}</p>
            </th>
            <th className="p-2 lg:p-4 border-l">
              <p className="text-left text-[10px] md:text-[12px] lg:text-[14px] font-[400]">{'Created'}</p>
            </th>
          </tr>
        </thead>

        <tbody>
          {values.map((value: TSuggestion, index: number) => (
            <tr
              key={index}
              className={
                classNames(
                  'text-gray-700 cursor-pointer hover:bg-[#0035ff1a] transition',
                  { 'border-b': index !== values.length - 1 }
                )
              }
              onClick={(): void => setSelected(value)}
            >
              <td className="p-2 lg:p-4 border-r">
                <p className="text-[10px] md:text-[12px]">{value.title}</p>
              </td>
              <td className="p-2 lg:p-4 border-l border-r">
                {value.match_rating ? (
                  <p
                    className="text-[10px] md:text-[12px]"
                    style={{
                      color: suggestionMatchRatingColor(value.match_rating)
                    }}
                  >
                    {value.match_rating}
                  </p>
                ) : (
                  <p className="text-[10px] md:text-[12px]">{'Not available'}</p>
                )
                }
              </td>
              <td className="p-2 lg:p-4 border-l border-r">
                <p className={`text-[10px] md:text-[12px] ${suggestionDemandFieldStyles(value.demand)}`}>
                  {value.demand}
                </p>
              </td>
              <td className="p-2 lg:p-4 border-l">
                <p className="text-[10px] md:text-[12px]">{value.created_at}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default UserSuggestions
