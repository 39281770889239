import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { HttpError, RefreshResult } from './types'
import { Authorization } from '../../types/types'
import {
  STATUS_BAD_REQUEST,
  STATUS_CONFLICT,
  STATUS_FORBIDDEN,
  STATUS_NOT_FOUND,
  STATUS_OK, STATUS_TOO_MANY_REQUESTS,
  STATUS_UNAUTHORIZED
} from './constants'
import {
  BadRequestError,
  ConflictError,
  ExpiredTokenError,
  InvalidGoogleSignInError,
  NotFoundError,
  TooManyRequestsError
} from './errors'
import { AuthorizationContext } from '../../context/authorization.context'

export const axiosResponseInterceptorWithRefresh = async (
  error: any,
  address: string,
  client: AxiosInstance,
  authorizationContext: AuthorizationContext,
): Promise<void> => {
  const originalRequest = error.config

  switch (error.response.status) {
  case STATUS_BAD_REQUEST: {
    if (error.response.data.message.includes('invalid sign in method for a Google account')) {
      throw new InvalidGoogleSignInError(error.response.data.message)
    }

    throw new BadRequestError(error.response.data.message)
  }
  case STATUS_UNAUTHORIZED: {
    const httpError: HttpError = error.response.data as HttpError
    if (httpError.message.includes('token has expired')) {
      if (!authorizationContext.value) {
        authorizationContext.signOut()
        return
      }

      try {
        const refreshResult: RefreshResult = await refresh(address, authorizationContext.value)

        originalRequest.headers['Authorization'] = `Bearer ${refreshResult.auth_token}`

        authorizationContext.refresh(refreshResult.auth_token)

        return await client(originalRequest)
      } catch (error) {
        console.error(error)

        authorizationContext.signOut()

        throw new ExpiredTokenError()
      }
    }

    authorizationContext.signOut()

    return Promise.reject(new Error(httpError.message))
  }
  case STATUS_FORBIDDEN: {
    const httpError: HttpError = error.response.data as HttpError
    return Promise.reject(new Error(httpError.message))
  }
  case STATUS_NOT_FOUND: {
    throw new NotFoundError()
  }
  case STATUS_CONFLICT: {
    throw new ConflictError()
  }
  case STATUS_TOO_MANY_REQUESTS: {
    throw new TooManyRequestsError()
  }
  default:
    return Promise.reject(new Error(`unexpected status code ${error.response.status}`))
  }
}

const refresh = async (address: string, authorization: Authorization): Promise<RefreshResult> => {
  const result: AxiosResponse = await axios.post(`${address}/refresh`, {}, {
    headers: {
      'Authorization': `Bearer ${authorization.refresh_token}`,
    },
    validateStatus: (status: number): boolean => status == STATUS_OK
  })

  return result.data as RefreshResult
}
