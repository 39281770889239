import React from 'react'
import Router from './components/Router'
import { AuthorizationProvider } from './context/authorization.context'
import { NotifyProvider } from './context/notify.context'
import { PopperProvider } from './context/popper.context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ModalProvider } from './context/modal.context'

const queryClient = new QueryClient()

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthorizationProvider>
      <ModalProvider>
        <NotifyProvider>
          <PopperProvider>
            <Router />
          </PopperProvider>
        </NotifyProvider>
      </ModalProvider>
    </AuthorizationProvider>
  </QueryClientProvider>
)

export default App
