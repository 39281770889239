import React, { ReactElement } from 'react'
import { Course } from '../types/types'
import { ReactComponent as AtIcon } from '../media/at-icon.svg'
import { ReactComponent as StarIcon } from '../media/star-icon.svg'
import { ReactComponent as StudentsIcon } from '../media/students-icon.svg'
import PropTypes from 'prop-types'

type courseCard = {
    value: Course
}

const CourseCard: React.FC<courseCard> = ({ value }): ReactElement => (
  <a
    className="w-full p-2 border-[1px] border-[#e4e0e1] rounded-[8px] bg-white cursor-pointer duration-300 ease-in-out hover:border-[#0035ff] text-left"
    href={value.url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <p className="text-[11px] xl:text-[13px] font-[500] max-two-lines">{value.title}</p>

    <div className="mt-1"/>

    <p className="text-[11px] xl:text-[13px] text-[#575757] font-[400] max-three-lines">{value.headline}</p>

    <div className="mt-2"/>

    <div className="border-b-[1px] border-dashed border-b-[#e4e0e1]"/>

    <div className="mt-2"/>

    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <AtIcon className="w-[14px] h-[14px] stroke-[#575757]" />

        <div className="ml-[0.125rem]"/>

        <p className="text-[11px] xl:text-[13px] text-[#575757] font-[400]">{value.source}</p>
      </div>

      <div className="flex items-center">
        <div className="flex items-center">
          <StarIcon className="w-[14px] h-[14px] stroke-[#575757]" />

          <div className="ml-[0.125rem]"/>

          <p className="text-[11px] xl:text-[13px] text-[#575757] font-[400]">{`${value.rating}/5`}</p>
        </div>

        <div className="ml-4"/>

        <div className="flex items-center">
          <StudentsIcon className="w-[14px] h-[14px] stroke-[#575757]"/>

          <div className="ml-[0.125rem]"/>

          <p className="text-[11px] xl:text-[13px] text-[#575757] font-[400]">{value.subscribers}</p>
        </div>
      </div>
    </div>
  </a>
)

CourseCard.propTypes = {
  value: PropTypes.shape({
    source: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    subscribers: PropTypes.string.isRequired,
  }).isRequired
}

export default CourseCard
