import React, { ReactElement } from 'react'
import logo from '../media/Mercedes-Benz-logo-E47583B444-seeklogo.com.png'
import { ReactComponent as GitHubLogo } from '../media/github-e74d48ed6bd519eddfaac1a76a313eed359b3ab311dccab980003682a3d3a05e.svg'
import { helloEmail } from '../constants/constants'

const Footer = (): ReactElement => (
  <section className="bg-[#f8f8f8] border-t-[1px] border-[#e4e0e1] flex flex-col justify-center items-center w-full">
    <div className="w-full max-w-[1000px] px-4 lg:px-0">
      <div className="mt-4 sm:mt-0"/>

      <div className="box-content flex flex-col sm:flex-row justify-between items-center sm:max-w-[1000px] sm:h-[45px] sm:py-[2.5rem] sm:pb-[0.25rem]">
        <div className="flex items-center">
          <img
            className="w-[20px] sm:w-[26px]"
            src={logo}
            alt="Few Steps Closer logo"
            loading="lazy"
          />

          <div className="ml-2"/>

          <p className="text-[12px] font-[500]">
            {'Few Steps Closer'}
          </p>
        </div>

        <div className="mt-4 sm:mt-0"/>

        <p className="text-[11px]">{helloEmail}</p>

        <div className="mt-4 sm:mt-0"/>

        <div className="flex">
          <a
            href="https://github.com/few-steps-closer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubLogo />
          </a>
        </div>
      </div>

      <div className="sm:border-t-[1px] sm:border-[#e4e0e1] flex justify-center sm:justify-start">
        <p className="text-[#575757] py-2 text-[10px] font-[300]">
          {'© 2024 Few Steps Closer. All rights reserved.'}
        </p>
      </div>
    </div>
  </section>
)

export default Footer
