export const homeRoute: string = '/'
export const signInRoute: string = '/sign-in'
export const signUpRoute: string = '/sign-up'
export const authGoogleCallback: string = '/auth/google/callback'
export const createSuggestionsFormRoute: string = '/s'
export const threads: string = '/s'
export const threadSuggestions: string = '/s/:threadId'
export const bookmarkedSuggestionsRoute: string = '/bookmarked'

export const constructThreadsSuggestionsRoute = (threadId: string): string => `${threads}/${threadId}`

