import React, { createContext, ReactElement, useContext, useEffect, useRef, useState } from 'react'

type modalContent = {
    title: string
    subtitle: string
    description: string
    deleteButtonClick: () => void
}

export type ModalContext = {
    show: (_content: modalContent) => void
}

const modalContext: React.Context<ModalContext> = createContext<ModalContext>({
  show: (): void => {}
})

type contextProviderProps = {
    children?: ReactElement | ReactElement[]
}

export const ModalProvider: React.FC<contextProviderProps> = ({ children }): ReactElement => {
  const [content, setContent] = useState<modalContent | null>()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const show = (content: modalContent): void =>
    setContent(content)

  const cancelButtonClick = (): void =>
    setContent(null)

  useEffect((): () => void  => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        content &&
                wrapperRef.current &&
                contentRef.current &&
                (
                  wrapperRef.current.contains(event.target as Node) &&
                    !contentRef.current.contains(event.target as Node)
                )
      ) {
        setContent(null)
      }
    }

    if (content) {
      document.addEventListener('click', handleClickOutside)
      document.body.style.overflow = 'hidden'
    }

    return (): void => {
      document.removeEventListener('click', handleClickOutside)
      document.body.style.overflow = ''
    }
  }, [content])

  return (
    <modalContext.Provider value={{ show: show }}>
      <>
        {children}

        {content && (
          <div     ref={wrapperRef}
            className="flex w-full h-full items-center justify-center p-4 md:0 z-50 fixed bg-black md:bg-[#00000090]"
          >
            <div       ref={contentRef}
              className="flex flex-col items-center justify-center bg-white rounded-[10px] overflow-auto w-[500px]"
            >
              <div className="w-full h-full p-4 border-b-[1px] border-b-[#e4e0e1]">
                <p className="text-[14px] lg:text-[16px] font-[500]">{content?.title}</p>
              </div>

              <div className="w-full h-full p-4">
                <p className="text-[12px] lg:text-[14px]">{content?.subtitle}</p>

                <div className="mt-2"/>

                <p className="text-[12px] lg:text-[14px]">{content?.description}</p>

                <div className="mt-4"/>

                <div className="flex justify-end">
                  <button
                    className="p-2 px-3 rounded-[10px] bg-white border-[1px] border-[#e4e0e1] hover:border-[#0035ff] transition"
                    onClick={() => cancelButtonClick()}
                  >
                    <p className="text-[12px] lg:text-[14px]">{'Cancel'}</p>
                  </button>

                  <div className="ml-2"/>

                  <button
                    className="p-2 px-3 rounded-[10px] text-white bg-red-500 hover:bg-red-600 transition"
                    onClick={(): void => {
                      content?.deleteButtonClick()
                      setContent(null)
                    }}
                  >
                    <p className="text-[12px] lg:text-[14px]">{'Delete'}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </modalContext.Provider>
  )
}

export const useModal = (): ModalContext =>
  useContext(modalContext)
