import React, { ReactElement } from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { homeRoute } from '../components/routes'
import { helloEmail } from '../constants/constants'
import PropTypes from 'prop-types'

type notFoundProps = {
    platform?: boolean
}

const NotFound: React.FC<notFoundProps> = ({ platform = false }): ReactElement => (
  <div className="flex flex-col w-full h-full">
    {!platform && <Navigation />}

    <div className="w-full h-full flex flex-col items-center justify-center text-center p-6">
      <p className="text-[12px] md:text-[14px]">{'404'}</p>
      <p className="text-[28px] md:text-[36px] font-[500]">{'Page not found!'}</p>
      <div className="mt-2"/>
      <p className="text-[14px] md:text-[16px] text-[#575757]">{'The page you’re looking for can’t be found.'}</p>
      <p className="text-[14px] md:text-[16px] text-[#575757]">
        {'Feel free to return to '}
        <a className="text-[#0035ff]" href={homeRoute}>{'our home page'}</a>
        {', or '}
        <a className="text-[#0035ff]" href={`mailto:${helloEmail}`}>{'let us know'}</a>
        {'.'}
      </p>
    </div>

    {!platform && <Footer />}
  </div>
)

NotFound.propTypes = {
  platform: PropTypes.bool
}

export default NotFound
