import React, { createContext, ReactElement, useContext, useMemo, useState } from 'react'
import { Authorization } from '../types/types'
import { signInRoute } from '../components/routes'

export type AuthorizationContext = {
    value?: Authorization
    signIn: (_authorization: Authorization) => void
    refresh: (_authToken: string) => void
    signOut: () => void
}

const authorizationContext: React.Context<AuthorizationContext> = createContext<AuthorizationContext>({
  signIn: (): void => {},
  refresh: (): void => {},
  signOut: (): void => {}
})

type contextProviderProps = {
    children?: ReactElement | ReactElement[]
}

export const AuthorizationProvider: React.FC<contextProviderProps> = ({ children }): ReactElement => {
  const localStorageItem: string | null = localStorage.getItem('authorization')

  const [value, setValue] = useState<Authorization | undefined>(
    localStorageItem ? JSON.parse(localStorageItem) : undefined
  )

  const contextValue: AuthorizationContext = useMemo((): AuthorizationContext => {
    const signOut = ():  void => {
      localStorage.removeItem('authorization')
      setValue(undefined)
      window.location.href = signInRoute
    }

    return {
      value: value,
      signIn: (authorization: Authorization): void => {
        localStorage.setItem('authorization', JSON.stringify(authorization))
        setValue(authorization)
      },
      refresh: (authToken: string): void => {
        if (value) {
          const refreshedAuthorization: Authorization = {
            ...value, auth_token: authToken
          }

          localStorage.setItem('authorization', JSON.stringify(refreshedAuthorization))

          setValue(refreshedAuthorization)
        } else {
          signOut()
        }
      },
      signOut: signOut,
    }
  }, [value])

  return (
    <authorizationContext.Provider value={contextValue}>
      {children}
    </authorizationContext.Provider>
  )
}

export const useAuthorization = (): AuthorizationContext =>
  useContext(authorizationContext)
