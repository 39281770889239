import React, { ReactElement, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as RemoveIcon } from '../media/remove-icon.svg'
import { ReactComponent as AddIcon } from '../media/add-icon.svg'

type multiInputProps = {
    label: string
    items: string[]
    placeholder?: string
    setItem: (_value: string, _index: number) => void
    removeItem: (_index: number) => void
    addButtonPlaceholder: string
    max?: number
}

const MultiInput: React.FC<multiInputProps> = ({
  label,
  items,
  placeholder,
  setItem,
  removeItem,
  addButtonPlaceholder,
  max = 20,
}): ReactElement => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  useEffect((): void => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight
    }
  }, [items])

  const hasMaximumItems = (): boolean => items.length === max
  const hasAnEmptyValue = (): boolean => items.filter((item: string): boolean => item === '').length > 0

  const canAddNewItem = (): boolean => !hasAnEmptyValue() && !hasMaximumItems()

  const addButtonClick = (): void => {
    setItem('', items.length)

    setTimeout((): void => {
      const newItemIndex: number = items.length
      if (inputRefs.current[newItemIndex]) {
        inputRefs.current[newItemIndex]?.focus()
      }
    }, 0)
  }

  const onEnter = (): void => {
    if (canAddNewItem()) {
      addButtonClick()
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-between box-border text-[12px] lg:text-[14px] py-2 pl-1">
        <label>{label}</label>
        <label className="text-[#575757]">{`${items.length}/${max}`}</label>
      </div>

      <div ref={scrollContainerRef} className="flex flex-col p-2 text-[12px] lg:text-[14px] border-[1px] border-[#e4e0e1] rounded-[10px] max-h-[280px] md:max-h-[320px] overflow-y-auto bg-[#f5f5f5]">
        {items.map((item: string, index: number) => (
          <div key={index} className="flex items-center mb-1 last:mb-0">
            <input
              ref={(el) => (inputRefs.current[index] = el)}
              className="w-full p-2 text-[12px] lg:text-[14px] border-[1px] border-[#e4e0e1] rounded-[10px] bg-white focus:outline-[#0035ff]"
              placeholder={placeholder && `eg. ${placeholder}`}
              value={item}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setItem(event.target.value, index)}
              onKeyDown={(event) => event.key === 'Enter' && onEnter()}
            />

            <button
              className="flex ml-2 group"
              type="button"
              onClick={(): void => removeItem(index)}
            >
              <RemoveIcon className="w-[12px] h-[12px] transition-all duration-300 ease-in-out group-hover:stroke-red-500 group-hover:fill-red-500" />
            </button>
          </div>
        ))}

        {
          (canAddNewItem()) && (
            <>
              {items.length > 0 && <div className="mt-1" />}

              <button
                className="pl-2 flex items-center text-start text-[#575757] duration-300 ease-in-out hover:text-[#0035ff] group"
                type="button"
                onClick={addButtonClick}
              >
                <AddIcon className="w-[13px] h-[13px] transition-all duration-300 ease-in-out group-hover:fill-[#0035ff]" />

                <div className="ml-[0.125rem]"/>

                {addButtonPlaceholder}
              </button>
            </>
          )
        }
      </div>
    </div>
  )
}

MultiInput.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  addButtonPlaceholder: PropTypes.string.isRequired,
  max: PropTypes.number,
}

export default MultiInput
