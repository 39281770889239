export class ExpiredTokenError extends Error {
  constructor() {
    super('User token has expired')
  }
}

export class BadRequestError extends Error {
  message: string

  constructor(message: string) {
    super('Bad request error')
    this.message = message
  }
}

export class InvalidGoogleSignInError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export class NotFoundError extends Error {
  constructor() {
    super('Not found error')
  }
}

export class ConflictError extends Error {
  constructor() {
    super('Conflict error')
  }
}

export class TooManyRequestsError extends Error {
  constructor() {
    super('Too many requests error')
  }
}
