import React, { ReactElement, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ShowPasswordIcon } from '../media/show-password-icon.svg'
import { ReactComponent as HidePasswordIcon } from '../media/hide-password-icon.svg'
import { findFieldInValidationErrorMessage } from './helpers'
import classNames from 'classnames'

type inputProps = {
    name: string
    label: string
    placeholder: string
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
    password?: boolean
    rules?: boolean
    error?: string
}

const Input: React.FC<inputProps> = ({
  name,
  label,
  placeholder,
  value,
  setValue,
  password = false,
  rules = false,
  error
}): ReactElement => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [inputChanged, setInputChanged] = useState<boolean>(false)

  const passwordIcon = (): ReactElement => {
    let icon: ReactElement = <ShowPasswordIcon className="w-[18px] h-[18px] top-0 md:top-1 right-2 absolute cursor-pointer transition hover:fill-[#0035ff]" />

    if (showPassword) {
      icon = <HidePasswordIcon className="w-[18px] h-[18px] top-0 md:top-1 right-2 absolute cursor-pointer transition hover:fill-[#0035ff]" />
    }

    return (
      <div onClick={(): void => setShowPassword(!showPassword)}>
        {icon}
      </div>
    )
  }

  const hasError = (): boolean => {
    if (!error) {
      return false
    }

    return findFieldInValidationErrorMessage(error).includes(name)
  }

  const handleErrorMessage = (): string => {
    if (!error) {
      return ''
    }

    if (error.includes('fails to match the required pattern')) {
      return 'Fails to match the required pattern'
    }

    const wrongField: string = findFieldInValidationErrorMessage(error)

    let message: string = error.replace(wrongField, '')
    message = message.trimStart()
    message = message.charAt(0).toUpperCase() + message.slice(1)

    return message
  }

  return (
    <div className="flex flex-col relative">
      <label className="text-[12px] lg:text-[14px] pb-1 pl-2">{label}</label>

      <div className="flex flex-col w-full">
        <input
          className={
            classNames(
              'rounded-[10px] p-2 text-[12px] lg:text-[14px] focus:outline-[#0035ff]',
              hasError()? 'border-[1px] border-red-500' : 'border-[1px] border-[#e4e0e1]'
            )
          }
          placeholder={placeholder}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value)
            setInputChanged(true)
          }}
          type={password && !showPassword ? 'password' : 'text'}
        />

        {rules && password && inputChanged && (
          <>
            <ul className="flex flex-col items-start p-2 pb-0 list-disc text-[12px]">
              <p>{'Your password must contain:'}</p>

              <li className="ml-6">
                <p className="text-[12px]">{'At least one lowercase & uppercase character'}</p>
              </li>
              <li className="ml-6">
                <p className="text-[12px]">{'At least one special character'}</p>
              </li>
              <li className="ml-6">
                <p className="text-[12px]">{'8 - 64 characters'}</p>
              </li>
            </ul>
          </>
        )}

        {hasError() && (
          <p className="left-[8px] bottom-[-20px] absolute text-red-500 text-[12px] lg:text-[13px]">{handleErrorMessage()}</p>
        )}
      </div>

      {password && passwordIcon()}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  password: PropTypes.bool,
  rules: PropTypes.bool,
  error: PropTypes.string
}

export default Input
