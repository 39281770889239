import React, { FormEvent, ReactElement } from 'react'
import PropTypes from 'prop-types'

type formStepProps = {
    title: string
    descriptions: string[]
    children?: ReactElement | ReactElement[]
    submit: (_event: FormEvent) => void
}

const FormStep: React.FC<formStepProps>= ({
  title,
  descriptions,
  children,
  submit
}): ReactElement => {
  return (
    <div className="flex flex-col w-full items-center">
      <p className="text-[30px] lg:text-[34px] font-[500] text-center leading-[35px]">{title}</p>

      <div className="mt-8 lg:mt-10" />

      {descriptions.map((description: string, index: number) => (
        <p
          key={index}
          className="text-[12px] md:text-[15px] text-[#575757] text-center"
        >
          {description}
        </p>
      ))}

      <div className="mt-8 lg:mt-10" />

      <form
        className="w-full overflow-y-auto p-5 mx-auto border-[1px] border-[#e4e0e1] rounded-[10px]"
        onSubmit={submit}
        style={{
          boxShadow: '0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
        }}
      >
        {children}

        <div className="mt-4" />

        <button
          className="text-white bg-[#0035ff] flex w-full items-center justify-center p-2 text-[12px] lg:text-[14px] font-[400] rounded-[10px] transition hover:bg-[#0029c4]"
          type="button"
          onClick={submit}
        >
          {'Continue'}
        </button>
      </form>
    </div>
  )
}

FormStep.propTypes = {
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  submit: PropTypes.func.isRequired,
}

export default FormStep
