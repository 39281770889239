import React, { ReactElement, useEffect } from 'react'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import { Navigate, useSearchParams } from 'react-router-dom'
import { createSuggestionsFormRoute, signInRoute } from './routes'
import { useNotify } from '../context/notify.context'

const AuthGoogleCallback = (): ReactElement => {
  const [searchParams] = useSearchParams()
  const authorizationContext: AuthorizationContext = useAuthorization()
  const { notify } = useNotify()

  const userId: string | null = searchParams.get('user_id')
  const authToken: string | null = searchParams.get('auth_token')
  const refreshToken: string | null = searchParams.get('refresh_token')

  useEffect((): void => {
    if (!userId || !authToken || !refreshToken) {
      notify('There was an unknown issue with your Google sign in.', 'error')
      return
    }

    notify('You were successfully signed in using your Google account.', 'success')
  }, [userId, authToken, refreshToken, notify])

  if (!userId || !authToken || !refreshToken) {
    return <Navigate to={signInRoute} />
  }

  authorizationContext.signIn({
    user_id: userId,
    auth_token: authToken,
    refresh_token: refreshToken,
  })

  return <Navigate to={createSuggestionsFormRoute} />
}

export default AuthGoogleCallback
