import React, { ReactElement, useEffect, useState } from 'react'
import { quotes } from '../constants/quotes'
import classNames from 'classnames'

const QuotesBlock = (): ReactElement => {
  const numberOfQuotes: number = 5

  const [randomQuotes, setRandomQuotes] = useState<React.ReactNode[]>([])
  const [activeIndex, setActiveIndex] = useState<number>(0)

  useEffect((): void => {
    const randomQuotes = quotes.sort((): number => 0.5 - Math.random()).slice(0, numberOfQuotes)
    setRandomQuotes(randomQuotes)
  }, [])

  useEffect((): () => void => {
    const timer = setTimeout((): void => {
      let nextIndex: number = activeIndex + 1

      if (nextIndex >= numberOfQuotes) {
        nextIndex = 0
      }

      setActiveIndex(nextIndex)
    }, 5000)

    return (): void => clearTimeout(timer)
  }, [activeIndex])

  const dots: unknown[] = Array(numberOfQuotes).fill(null)

  return (
    <div className="relative w-full h-[100px]">
      <div className="flex gap-4 justify-center absolute left-[50%] transform -translate-x-1/2">
        {dots.map((_,index: number) => (
          <div     key={index}
            className={
              classNames(
                'w-[10px] h-[10px] border-[1px] border-[#0035ff] rounded-full cursor-pointer transition-all',
                { 'bg-white': index !== activeIndex },
                { 'bg-[#0035ff]': index === activeIndex }
              )
            }
            onClick={(): void => setActiveIndex(index)}
          />
        ))}
      </div>

      <div className="mt-8 lg:mt-10"/>

      <div className="text-center text-[14px] md:text-[16px] text-[#575757]">
        {randomQuotes[activeIndex]}
      </div>
    </div>
  )
}

export default QuotesBlock
