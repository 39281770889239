export const skillsPlaceholders: string[] = [
  'Communication',
  'Teamwork',
  'Leadership',
  'Problem-Solving',
  'Time Management',
  'Adaptability',
  'Creativity',
  'Critical Thinking',
  'Collaboration',
  'Negotiation',
  'Project Management',
  'Public Speaking',
  'Decision Making',
  'Conflict Resolution',
  'Emotional Intelligence',
  'Networking',
  'Organizational Skills',
  'Writing',
  'Data Analysis',
  'Coding',
  'Software Development',
  'Digital Marketing',
  'Sales',
  'Customer Service',
  'Financial Management',
  'Content Creation',
  'Video Editing',
  'Graphic Design',
  'Web Development',
  'Machine Learning',
  'Cybersecurity',
  'Research',
  'Market Research',
  'Presentation Skills',
  'Accounting',
  'Entrepreneurship',
  'Social Media Management',
  'Photography',
  'SEO (Search Engine Optimization)',
  'Branding',
  'Public Relations',
  'User Experience (UX) Design',
  'Product Management',
  'Human Resources',
  'Artificial Intelligence',
  'Database Management',
  'Negotiation Skills',
  'Business Strategy',
  'Technical Writing',
  'Event Planning',
  'Sales Strategy',
  'Conflict Management'
]

export const interestsPlaceholders: string[] = [
  'Traveling',
  'Cooking',
  'Photography',
  'Reading',
  'Fitness',
  'Music',
  'Technology',
  'Gaming',
  'Sports',
  'Art & Design',
  'Writing',
  'Fashion',
  'Animal Care',
  'Gardening',
  'Politics',
  'Film & TV',
  'Volunteering',
  'Science',
  'Social Media',
  'Entrepreneurship',
  'Technology Innovation',
  'Health & Wellness',
  'Nature',
  'Education',
  'Science & Research',
  'History',
  'Psychology',
  'Space Exploration',
  'Environmental Sustainability',
  'Cultural Events',
  'Philanthropy',
  'Business & Finance',
  'Personal Development',
  'Cryptocurrency',
  'Artificial Intelligence',
  'Coding & Programming',
  'Social Issues',
  'Literature',
  'Music Production',
  'Interior Design',
  'Architecture',
  'Gaming Technology',
  'Video Editing',
  'Travel Vlogging',
  'Event Planning',
  'Sports Analytics',
  'Digital Marketing',
  'Public Speaking',
  'Language Learning',
  'Blogging',
  'Film Making',
  'DIY Projects',
  'Photography Editing',
  'Animal Conservation',
  'Podcasting',
  'Art History',
  'Leadership Development',
  'Project Management',
  'Marketing Strategies',
  'Digital Transformation',
  'Business Strategy',
  'Financial Planning',
  'Data Science',
  'Machine Learning',
  'Cybersecurity',
  'Software Development',
  'Web Development',
  'UI/UX Design',
  'Product Management',
  'Branding',
  'Public Relations',
  'Social Media Marketing',
  'Search Engine Optimization (SEO)',
  'Content Marketing',
  'Customer Experience',
  'Human Resources Management',
  'Supply Chain Management',
  'Operations Management',
  'E-commerce Strategies',
  'Business Analytics',
  'Sales Strategy',
  'Corporate Communications',
  'Global Business',
  'Business Intelligence',
  'Consulting',
  'Legal & Compliance',
  'Corporate Social Responsibility (CSR)',
  'Health & Wellness Programs',
  'Innovation & R&D',
  'Corporate Training',
  'Employee Engagement',
  'Product Launches',
  'Team Management',
  'Venture Capital & Investments',
  'Real Estate Development',
  'Startups',
  'Cloud Computing',
  'Content Creation',
  'Video Production',
  'Data Analytics',
  'Marketing Automation',
  'Event Planning & Management',
  'Freelancing',
  'Technology Integration',
  'Regulatory Affairs',
  'Sustainability Initiatives',
  'Risk Management'
]

export const notesPlaceholders: string[] = [
  "\"I’ve recently completed an online course in digital marketing and am actively looking for opportunities in the field. I'm especially interested in SEO and content creation roles.\"",
  '"Although I don’t have direct work experience in tech, I’ve been self-learning coding for about six months. I’m eager to transition into a software development career."',
  '"I’m passionate about environmental sustainability and would love to find a career that combines my skills in business management and my interest in green technologies. I’m also open to opportunities in project management."',
  '"I’m currently working part-time as a freelance graphic designer but am looking for full-time opportunities in a creative environment."',
  '"I’m bilingual in English and Spanish, and I’ve been thinking about pursuing a career in international business. My main interests are in supply chain management and global logistics."',
  '"I’ve been out of the workforce for a few years while taking care of family, but I’m now ready to re-enter the job market. I’m interested in remote customer service or project management roles."',
  '"I recently graduated with a degree in psychology and am considering a career in human resources."',
  '"I’m really passionate about data science and machine learning. I’m working on personal projects, but I’m struggling to find a full-time position that aligns with my skills."',
  '"I’ve been working in marketing for 5 years but feel like I’m stuck in a rut. I’d love to transition into a leadership role."',
  '"I’m currently balancing a full-time job and raising young children, so I’m looking for flexible career options."',
  '"I’ve been working in retail for several years but feel ready for a new challenge. I’m interested in going into tech but lack formal education in that field. I’m looking for advice on how to gain the necessary skills and get started in tech-related roles."',
]
