import React, { ReactElement } from 'react'
import { ReactComponent as BookmarksIcon } from '../media/bookmarks-icon.svg'
import { bookmarkedSuggestionsRoute } from './routes'
import classNames from 'classnames'
import { Location, useLocation } from 'react-router-dom'

const SidebarNavigation = (): ReactElement => {
  const location: Location = useLocation()

  const items = [
    {
      href: bookmarkedSuggestionsRoute,
      icon: BookmarksIcon,
      title: 'Bookmarked suggestions'
    }
  ]

  return (
    <div className="flex flex-col w-full">
      {items.map((item, index) => (
        <a
          key={index}
          href={item.href}
          className={
            classNames('flex p-2 rounded-[8px] hover:bg-[#0035ff1a] transition group',
              location.pathname.includes(item.href) ? 'bg-[#0035ff1a] stroke-[#0035ff] rounded-[8px] text-[#0035ff]': 'text-[#575757] stroke-[#575757]',
            )
          }
        >
          <item.icon className="w-[18px] h-[18px] md:w-[20px] md:h-[20px] group-hover:stroke-[#0035ff]"/>

          <div className="ml-2"/>

          <p className="text-[12px] lg:text-[14px] font-[500] group-hover:text-[#0035ff]">
            {item.title}
          </p>
        </a>
      ))}
    </div>
  )
}

export default SidebarNavigation
