import React, { createContext, ReactElement, useContext, useRef, useState } from 'react'
import { ReactComponent as NotificationSuccessIcon } from '../media/notifcation-success-icon.svg'
import { ReactComponent as NotificationErrorIcon } from '../media/notification-error-icon.svg'
import { ReactComponent as RemoveIcon } from '../media/remove-icon.svg'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

type notification = {
    id: number
    message: string
    type: 'success' | 'error'
}

export type NotifyContext = {
    notify: (_message: string, _type: 'success' | 'error') => void
}

const notifyContext: React.Context<NotifyContext> = createContext<NotifyContext>({
  notify: (): void => {}
})

type contextProviderProps = {
    children?: ReactElement | ReactElement[]
}

export const NotifyProvider: React.FC<contextProviderProps> = ({ children }): ReactElement => {
  const [notifications, setNotifications] = useState<notification[]>([])
  const ref = useRef(null)

  const notify = (message: string, type: 'success' | 'error'): void => {
    const id: number = new Date().getTime()

    const newNotification: notification = {
      id: id,
      message: message,
      type: type,
    }

    setNotifications([newNotification])

    setTimeout((): void => {
      setNotifications((prev: notification[]) =>
        prev.filter((n: notification): boolean =>
          n.id !== id
        )
      )
    }, 3500)
  }

  const removeNotification = (id: number): void => {
    setNotifications((prev: notification[]) =>
      prev.filter((notif: notification): boolean =>
        notif.id !== id
      )
    )
  }

  return (
    <notifyContext.Provider value={{ notify: notify }}>
      {children}

      <div className="flex flex-col mb-2 md:m-6 w-[16rem] md:w-[22rem] right-1/2 translate-x-1/2 md:translate-x-0 bottom-0 md:right-0 absolute z-20">
        <TransitionGroup className="relative">
          {notifications.map((notification: notification) => (
            <CSSTransition
              key={notification.id}
              timeout={600}
              classNames="notification"
            >
              <div         ref={ref}
                className="flex justify-between items-center p-3 w-full rounded-[10px] bg-[#2b2b2b] text-white absolute bottom-0 right-0"
                style={{
                  boxShadow: '0 9px 31px rgba(73, 73, 73, 0.5)'
                }}
              >
                {
                  notification.type === 'success' ? (
                    <NotificationSuccessIcon className="w-[18px] h-[18px] md:w-[22px] md:h-[22px] shrink-0 stroke-green-400"/>
                  ) : (
                    <NotificationErrorIcon className="w-[18px] h-[18px] md:w-[22px] md:h-[22px] shrink-0 stroke-red-500"/>
                  )
                }

                <p className="mx-3 text-[12px] lg:text-[14px] whitespace-pre-wrap overflow-hidden">{notification.message}</p>

                <RemoveIcon
                  className="shrink-0 w-[12px] h-[12px] md:w-[14px] md:h-[14px] fill-[#959595] stroke-[#959595] cursor-pointer hover:fill-white hover:stroke-white transition"
                  onClick={() => removeNotification(notification.id)}
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </notifyContext.Provider>
  )
}

export const useNotify = (): NotifyContext =>
  useContext(notifyContext)
