import React, { ReactElement } from 'react'
import logo from '../media/Mercedes-Benz-logo-E47583B444-seeklogo.com.png'
import { signInRoute, signUpRoute } from './routes'

const Hero = (): ReactElement => (
  <section className="flex flex-col justify-center lg:h-[100vh] gradient px-10 md:px-16 lg:px-0">
    <div className="mt-20 lg:mt-0" />

    <div className="flex flex-col items-center">
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-[26px]"
          src={logo}
          alt="Few Steps Closer logo"
          loading="lazy"
        />

        <div className="mt-1.5"/>

        <p className="text-[14px] font-[500] md:text-[16px]">
          {'Few Steps Closer'}
        </p>
      </div>

      <div className="mt-4 lg:mt-6"/>

      <h1 className="text-center normal-case break-normal text-[40px] md:text-[65px] lg:text-[70px] font-[500] leading-[40px] md:leading-[60px] lg:leading-[80px]">
        {'Discover your dream career & make the first steps with the power of AI'}
      </h1>

      <div className="mt-8 lg:mt-16" />

      <h2 className="text-[#575757] text-center text-[14px] md:text-[18px] font-normal">
        {'Uncover your future career with AI-generated career suggestions just for you, and explore personalized courses tailored to your journey—leap into the unknown now!'}
      </h2>

      <div className="mt-8 lg:mt-16"/>

      <div className="flex">
        <a
          href={signUpRoute}
          className="rounded-[10px] bg-[#0035ff] text-white text-[14px] md:text-[16px] text-center hover:bg-[#0029c4] transition p-4"
        >
          {'Start now for free'}
        </a>

        <div className="ml-2" />

        <a
          href={signInRoute}
          className="bg-white border border-[#e4e0e1] rounded-[10px] text-[14px] text-center md:text-[16px] hover:border-[#0035ff] transition p-4"
        >
          {'Sign in'}
        </a>
      </div>
    </div>
  </section>
)

export default Hero
