import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'

type formStepsBarProps = {
    step: number
    max: number
}

const FormStepsBar: React.FC<formStepsBarProps> = ({ step, max }): ReactElement => {
  const percentage: number = (step / max) * 100

  return (
    <div className="w-full">
      <p className="pb-1 text-[12px] lg:text-[13px] text-[#575757]">{`${step}/${max}`}</p>

      <div className="bg-[#0035ff] h-[2px] rounded-[10px] transition-all"
        style={{
          width: `${percentage}%`
        }}
      />
    </div>
  )
}

FormStepsBar.propTypes = {
  step: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
}

export default FormStepsBar
