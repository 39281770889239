import React, { FormEvent, ReactElement, useState } from 'react'
import Input from '../components/Input'
import logo from '../media/Mercedes-Benz-logo-E47583B444-seeklogo.com.png'
import { ReactComponent as GoogleLogo } from '../media/google-logo.svg'
import { SignInResult } from '../services/http/types'
import HttpClient from '../services/http/client'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HomeButton from '../components/HomeButton'
import { useNotify } from '../context/notify.context'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { BadRequestError, InvalidGoogleSignInError, NotFoundError } from '../services/http/errors'
import { constructValidateErrorMessage, validateEmail, validateIsEmpty } from './validate'

type signInMutation = { email: string, password: string }

const SignIn = (): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()
  const { notify } = useNotify()

  const client: HttpClient = new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  )

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [validationErrorMessage, setValidationErrorMessage] = useState<string>()

  const signInMutation: UseMutationResult<SignInResult, Error, signInMutation> = useMutation(
    {
      mutationFn: async (args: signInMutation): Promise<SignInResult> => (
        await client.signIn(
          {
            email: args.email,
            password: args.password
          },
        )
      ),
      onSuccess: (result: SignInResult): void => {
        authorizationContext.signIn({
          user_id: result.user_id,
          auth_token: result.auth_token,
          refresh_token: result.refresh_token
        })

        notify('You were successfully signed in!', 'success')
      },
      onError: (error: Error): void => {
        if (error instanceof BadRequestError) {
          setValidationErrorMessage(error.message)
          signInMutation.reset()
          return
        }

        if (error instanceof InvalidGoogleSignInError) {
          notify('Account was created through Google sign in.', 'error')
          signInMutation.reset()
          return
        }

        if (error instanceof NotFoundError) {
          notify('Account doesn\'t exist.', 'error')
          signInMutation.reset()
          return
        }

        notify('There was an unknown error with your sign in.', 'error')
      }
    })

  const validate = (): boolean => {
    const [emailValidate, emailValidateMessage] = validateEmail(email)
    if (!emailValidate) {
      setValidationErrorMessage(emailValidateMessage)
      return false
    }

    if (validateIsEmpty(password)) {
      setValidationErrorMessage(constructValidateErrorMessage('password', 'input cannot be empty'))
      return false
    }

    return true
  }

  const formSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()

    if (!validate()) {
      return
    }

    signInMutation.mutate({
      email: email,
      password: password
    })
  }

  const googleSignInMutation: UseMutationResult<string, Error> = useMutation(
    {
      mutationFn: async (): Promise<string> => await client.signGoogle(),
      onSuccess: (result: string): void => {
        window.location.href = result
      },
      onError: (error: Error): void => {
        notify('There was an unknown error with your Google sign in.', 'error')
        throw error
      }
    })

  const signUpWithGoogleButtonClick = async (event: FormEvent): Promise<void> => {
    event.preventDefault()

    googleSignInMutation.mutate({})
  }

  return (
    <div className="flex flex-col items-center h-full w-[75%] md:w-[550px] md:max-w-[550px]">
      <HomeButton/>

      <div className="w-full h-full flex flex-col items-center justify-center">
        <img src={logo} loading="lazy" width="26" alt=""/>

        <div className="mt-4 lg:mt-6"/>

        <p className="text-[28px] lg:text-[34px] text-center font-[500]">{'Welcome back'}</p>

        <div className="mt-6"/>

        <form
          className="flex flex-col border-[1px] border-[#e4e0e1] rounded-[10px] p-5 mx-auto w-full"
          onSubmit={formSubmit}
          onChange={() => setValidationErrorMessage(undefined)}
          style={{
            boxShadow: '0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
          }}
        >
          <Input
            name={'email'}
            label={'Email'}
            placeholder={'Your email'}
            value={email}
            setValue={setEmail}
            error={validationErrorMessage}
          />

          <div className="mt-6"/>

          <Input
            name={'password'}
            label={'Password'}
            placeholder={'Your Password'}
            value={password}
            setValue={setPassword}
            password={true}
            error={validationErrorMessage}
          />

          <div className="mt-6"/>

          <button
            className="bg-[#0035ff] text-white flex w-full items-center justify-center p-2 rounded-[10px] text-[12px] lg:text-[14px] font-[400] transition hover:bg-[#0029c4]"
            type="submit"
          >
            {'Sign in'}
          </button>

          <div className="mt-4 lg:mt-6" />

          <div className="flex w-full flex-row before:content-[''] before:h-[0.5rem] before:flex-1 before:border-b-[1px] before:border-b-[#E4E0E1] after:content-[''] after:h-[0.5rem] after:flex-1 after:border-b-[1px] after:border-b-[#E4E0E1]">
            <span className="text-[11px] px-2">{'OR'}</span>
          </div>

          <div className="mt-4 lg:mt-6" />

          <button
            className="text-[#000] text-[12px] lg:text-[14px] border-[1px] border-[#e4e0e1] flex text-center align-middle w-full items-center justify-center p-2 rounded-[10px] font-[400] hover:border-[#0035ff] transition"
            type="button"
            onClick={signUpWithGoogleButtonClick}
          >
            <GoogleLogo className="h-[15px] w-[15px]"/>

            <div className="ml-2"/>

            {'Sign in with Google'}
          </button>

          <div className="mt-4"/>

          <p className="text-center text-[12px] lg:text-[13px] flex flex-col justify-center sm:flex-row whitespace-pre-wrap">
            {'Don\'t have an account? '}
            <span>
              <a className="text-center text-[12px] lg:text-[13px] text-[#0035ff] m-auto" href="/sign-up">
                {'Sign up for free'}
              </a>
              {'.'}
            </span>
          </p>
        </form>
      </div>
    </div>
  )
}

export default SignIn
