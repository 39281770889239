import React, { ReactElement } from 'react'

const Product = (): ReactElement => (
  <section className="px-10 md:px-16 lg:px-0">
    <div className="flex flex-col justify-center items-center">
      <div className="mt-20 lg:mt-0"/>

      <h3 className="text-[28px] lg:text-[38px] font-[500] text-center leading-[32px]">
        {'Still searching for a career you love?'}
      </h3>

      <div className="mt-8 lg:mt-10"/>

      <div className="text-[14px] md:text-[16px] text-[#575757] text-center">
        <p>{'You’re not alone—research shows that 75% of people haven’t found their dream career, and many never will.'}</p>
        <br/>
        <p>{'Let\'s change that together! With the power of AI, we\'re here to support you discover your future career and make your first steps easier. Suggestions with steps and courses will guide you as you begin an exciting new chapter in your life.'}</p>
      </div>
    </div>

    <div className="flex flex-col items-center lg:flex-row mt-10 lg:mt-12 overflow-visible gap-[16px]">
      <div className="flex flex-col justify-between items-stretch w-full lg:w-[340px] lg:h-[475px] border border-[#e4e0e1] rounded-[12px] overflow-hidden shadow-[0_9px_31px_#060c240a,0_2px_5px_#060c2408,0_0.5px_1px_#060c2405]">
        <div className="bg-center bg-no-repeat bg-cover bg-scroll border-b border-[#e4e0e1] h-[175px] sm:h-[350px] lg:h-[60%]"/>
        <div className="flex flex-col justify-start items-start lg:h-[40%] p-[0.75rem] sm:p-[1.5rem] shadow-[0_1px_3px_#ffffff40]">
          <h4 className="text-[14px] sm:text-[16px] font-[500]">
            {'1. Tell us more about yourself'}
          </h4>

          <div className="mt-2"/>

          <p className="text-[12px] sm:text-[13px] text-[#575757]">
            {'At first, you will be asked to answer a few quick questions to help us understand you better. The more you fill the better!'}
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-between items-stretch w-full lg:w-[340px] lg:h-[475px] border border-[#e4e0e1] rounded-[12px] overflow-hidden shadow-[0_9px_31px_#060c240a,0_2px_5px_#060c2408,0_0.5px_1px_#060c2405]">
        <div className="product-image-2 bg-no-repeat bg-[length:auto_100%] border-b border-[#e4e0e1] h-[175px] sm:h-[350px] lg:h-[60%]"/>
        <div className="flex flex-col justify-start items-start lg:h-[40%] p-[0.75rem] sm:p-[1.5rem] shadow-[0_1px_3px_#ffffff40]">
          <h4 className="text-[14px] sm:text-[16px] font-[500]">
            {'2. Receive suggestions'}
          </h4>

          <div className="mt-2"/>

          <p className="text-[12px] sm:text-[13px] text-[#575757]">
            {'Based on your answers, the AI model generates personalized career suggestions, complete with short description about the role.'}
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-between items-stretch w-full lg:w-[340px] lg:h-[475px] border border-[#e4e0e1] rounded-[12px] overflow-hidden shadow-[0_9px_31px_#060c240a,0_2px_5px_#060c2408,0_0.5px_1px_#060c2405]">
        <div className="product-image-3 bg-no-repeat bg-top bg-[length:auto_100%] border-b-[1px] border-[#e4e0e1] h-[175px] sm:h-[350px] lg:h-[60%]"/>
        <div className="flex flex-col justify-start items-start lg:h-[40%] p-[0.75rem] sm:p-[1.5rem] shadow-[0_1px_3px_#ffffff40]">
          <h4 className="text-[14px] sm:text-[16px] font-[500]">
            {'3. Kickstart your learning journey with steps and tailored courses'}
          </h4>

          <div className="mt-2"/>

          <p className="text-[12px] sm:text-[13px] text-[#575757]">
            {'Each career suggestion is paired with steps that have carefully selected courses, tailored for you, to help you start your journey with confidence and ease.'}
          </p>
        </div>
      </div>
    </div>

    <div className="mt-16 lg:mt-20"/>

    <div className="flex flex-col items-center ">
      <div className="text-center text-[14px] md:text-[16px] text-[#575757]">
        <p>
          {'"The only way to do great work is to love what you do. If you haven\'t found it yet, keep looking. Don\'t settle."'}
        </p>

        <div className="mt-1"/>

        <p>
          {'– Steve Jobs'}
        </p>
      </div>

      <div className="mt-16 lg:mt-20 w-[150px] lg:w-[200px] border-b-[1px] border-b-[#e4e0e1]"/>
    </div>
  </section>
)

export default Product
