import React, { ReactElement } from 'react'
import { ReactComponent as ArrowLeftIcon } from '../media/arrow-left-icon.svg'

const HomeButton = (): ReactElement => (
  <div className="absolute top-0 left-0 p-8 z-10">
    <a href="/" className="flex items-center gap-1 text-[12px] hover:text-[#575757] transition group">
      <ArrowLeftIcon className="w-[18px] h-[18px] md:w-[15px] md:h-[15px] duration-300 ease-in-out group-hover:fill-[#575757]"/>

      <p className="hidden md:flex">{'Back to Few Steps Closer'}</p>
    </a>
  </div>
)

export default HomeButton
