import React, { ReactElement, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Step as TStep } from '../types/types'
import Courses from './Courses'
import { ReactComponent as ArrowDownIcon } from '../media/small-arrow-down-icon.svg'
import { ReactComponent as ArrowUpIcon } from '../media/small-arrow-up-icon.svg'

type stepProps = {
    index: number
    value: TStep
    opened: boolean
    totalStepsCount: number
}

const Step: React.FC<stepProps> = ({ index, value, opened }): ReactElement => {
  const [openedCourses, setOpenedCourses] = useState<boolean>(opened)

  return (
    <div className="flex flex-col sm:pl-6 xl:pl-10 relative">
      <div className="mt-4" />

      <div className={
        classNames(
          'pt-2 overflow-hidden border-[1px] border-[#e4e0e1] rounded-[10px] after:content-[""] after:w-[1px] after:h-[16px] after:bottom-[-16px] after:left-[50%] after:bg-[#e4e0e1] z-[0] after:absolute',
          { 'hover:border-[#0035ff] duration-300 ease-in-out': !openedCourses },
        )
      }
      >
        <div   className={
          classNames(
            'flex justify-center pb-2 text-[12px] xl:text-[14px] font-[400] text-center cursor-pointer select-none relative top-0',
            { 'border-b-[1px] border-b-[#e4e0e1]': openedCourses }
          )
        }
        onClick={(): void => setOpenedCourses(!openedCourses)}
        >
          <p className="font-[500]">
            {`Step ${index + 1}:`}
          </p>

          <p className="whitespace-pre">
            {` ${value.title}`}
          </p>

          {openedCourses ?
            <ArrowDownIcon className="w-[13px] h-[13px] xl:w-[15px] xl:h-[15px] absolute right-2 xl:right-4 top-[0.125rem]" /> :
            <ArrowUpIcon className="w-[13px] h-[13px] xl:w-[15px] xl:h-[15px] absolute right-2 xl:right-4 top-[0.125rem]" />
          }
        </div>

        {openedCourses && <Courses values={value.courses} />}
      </div>
    </div>
  )
}

Step.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        headline: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        subscribers: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired
  }).isRequired,
  opened: PropTypes.bool.isRequired,
  totalStepsCount: PropTypes.number.isRequired,
}

export default Step
