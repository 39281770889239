import React, { FormEvent, ReactElement, useMemo, useState } from 'react'
import MultiInput from '../components/MultiInput'
import FormStep from '../components/FormStep'
import { CreateSuggestions, ThreadWithSuggestions, ThreadInput, Limits } from '../types/types'
import FormStepsBar from '../components/FormStepsBar'
import TextArea from '../components/TextArea'
import { AuthorizationContext, useAuthorization } from '../context/authorization.context'
import HttpClient from '../services/http/client'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { constructThreadsSuggestionsRoute } from '../components/routes'
import { ReactComponent as ArrowLeftIcon } from '../media/arrow-left-icon.svg'
import { ReactComponent as TryAgainIcon } from '../media/try-again-icon.svg'
import { interestsPlaceholders, notesPlaceholders, skillsPlaceholders } from '../constants/placeholders'
import { useNotify } from '../context/notify.context'
import { QueryClient, useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { helloEmail } from '../constants/constants'
import { BadRequestError, TooManyRequestsError } from '../services/http/errors'
import QuotesBlock from '../components/QuotesBlock'
import Client from '../services/http/client'
import classNames from 'classnames'

type createSuggestionsMutation = { userId: string, interests: string[], skills: string[], notes?: string }

const CreateSuggestionsForm = (): ReactElement => {
  const authorizationContext: AuthorizationContext = useAuthorization()
  const navigate: NavigateFunction = useNavigate()
  const { notify } = useNotify()
  const queryClient: QueryClient = useQueryClient()

  const currentStepsAmount: number = 3

  const client: HttpClient = useMemo((): Client => new HttpClient(
    process.env.REACT_APP_HTTP_SERVER_ADDRESS ?? 'http://127.0.0.1:8080',
    authorizationContext,
  ), [authorizationContext])

  const [formValue, setFormValue] = useState<CreateSuggestions>({
    interests: [],
    skills: [],
    notes: undefined
  })
  const [currentFormStepIndex, setCurrentFormStepIndex] = useState<number>(1)

  const { data: previousInputs } = useQuery(
    {
      queryKey: ['threadInputs', authorizationContext.value?.user_id],
      queryFn: async (): Promise<ThreadInput[]> => await client.userThreadInputs(authorizationContext.value?.user_id ?? ''),
      enabled: !!authorizationContext.value?.user_id,
    }
  )

  const {
    data: limits,
    isLoading: limitsLoading,
  } = useQuery(
    {
      queryKey: ['limits', authorizationContext.value?.user_id],
      queryFn: async (): Promise<Limits> =>
        client.userLimits(authorizationContext.value?.user_id ?? ''),
      enabled: !!authorizationContext.value?.user_id,
    }
  )

  const createSuggestionsMutation: UseMutationResult<ThreadWithSuggestions, Error, createSuggestionsMutation> = useMutation(
    {
      mutationFn: async (args: createSuggestionsMutation): Promise<ThreadWithSuggestions> => (
        await client.createThreadWithSuggestions(
          args.userId,
          {
            skills: args.skills,
            interests: args.interests,
            notes: args.notes,
          },
        )
      ),
      onSuccess: (result: ThreadWithSuggestions): void => {
        queryClient.invalidateQueries({
          queryKey: ['threads', authorizationContext.value?.user_id ?? '']
        })

        navigate(constructThreadsSuggestionsRoute(result.thread_id), {
          state: result
        })

        notify('Submission processed successfully!', 'success')
      },
      onError: (error: Error): void => {
        notify('There was an error processing your submission.', 'error')

        if (error instanceof BadRequestError) {
          createSuggestionsMutation.reset()
          return
        }

        throw error
      }
    })

  if (limitsLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <div className="w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff] spinner"/>

        <div className="mt-4 md:mt-6"/>

        <p className="text-[12px] md:text-[16px]">{'Loading...'}</p>
      </div>
    )
  }

  if (createSuggestionsMutation.isPending) {
    return (
      <div className="flex flex-col justify-center items-center h-full text-center">
        <div className="flex flex-col h-fit justify-center items-center">
          <div className="spinner w-[30px] md:w-[40px] rounded-full border-[4px] border-[#e4e0e1] border-r-[#0035ff]"/>

          <div className="mt-4 md:mt-6"/>

          <p className="text-[14px] md:text-[16px]">
            {'Thank you!'}
          </p>
          <p className="text-[14px] md:text-[16px]">
            {'You have made the first step towards finding your passion.'}
          </p>
          <p className="text-[14px] md:text-[16px]">
            {'This may take a moment. We’re analyzing your input to create personalized suggestions.'}
          </p>
        </div>

        <div className="mt-10 md:mt-14" />

        <QuotesBlock />
      </div>
    )
  }

  const cleanseArray = (arr: string[]) =>
    arr.filter((v: string): boolean => v.length > 1)

  if (limits?.suggestions_hit || (createSuggestionsMutation.isError && createSuggestionsMutation.error instanceof TooManyRequestsError)) {
    return (
      <div className="flex flex-col justify-center items-center text-center h-full text-[14px] md:text-[16px]">
        <p>{'Oops!'}</p>

        <p>{'You’ve hit the daily limit of three suggestions per day.'}</p>
        <p>{'Please try again tomorrow. Thank you for your understanding!'}</p>
      </div>
    )
  }

  if (createSuggestionsMutation.isError) {
    const tryAgainButtonClick = async (): Promise<void> => {
      const skills: string[] = cleanseArray(formValue.skills)
      const interests: string[] = cleanseArray(formValue.interests)

      if (currentFormStepIndex === currentStepsAmount) {
        await createSuggestionsMutation.mutate({
          userId: authorizationContext.value?.user_id ?? '',
          skills: skills,
          interests: interests,
        })

        return
      }
    }

    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <p className="text-[14px] md:text-[16px]">{'We are sorry, there was an unknown error with your submission.'}</p>
        <p className="text-[14px] md:text-[16px]">{'Our team has been already informed and we are working on resolving the issue.'}</p>

        <div className="mt-2 md:mt-4"/>

        <a
          className="text-[14px] md:text-[16px] text-[#0035ff] cursor-pointer"
          href={`mailto:${helloEmail}`}
        >
          {'Either reach out to us'}
        </a>

        <div className="mt-2 md:mt-4"/>

        <p className="text-[14px] md:text-[16px]">{'or'}</p>

        <div className="mt-2 md:mt-4"/>

        <button
          className="flex justify-between items-center p-3 rounded-[10px] bg-[#0035ff] text-white transition hover:bg-[#0029c4]"
          onClick={() => tryAgainButtonClick()}
        >
          <TryAgainIcon className="w-[16px] h-[16px] md:w-[18px] md:h-[18px] fill-transparent stroke-white"/>

          <div className="ml-2"/>

          <p className="text-[14px] md:text-[16px]">
            {'Try again'}
          </p>
        </button>
      </div>
    )
  }

  const previousFormButton = (): boolean | ReactElement => (
    <div
      className={
        classNames(
          'w-full',
          {
            'hidden': currentFormStepIndex === 1
          }
        )
      }
    >
      <button
        className="flex items-center text-[12px] md:text-[14px] hover:text-[#575757] group hover:color-[#575757]"
        onClick={() => setCurrentFormStepIndex((prev: number) => prev - 1)}
      >
        <ArrowLeftIcon className="w-[13px] h-[13px] md:w-[15px] md:h-[15px] duration-300 ease-in-out group-hover:fill-[#575757]"/>

        <div className="ml-1"/>

        <p>
          {'Previous step'}
        </p>
      </button>

      <div className="mb-6 md:mb-8"/>
    </div>
  )

  const setArrayValueAtIndex = <K extends keyof CreateSuggestions>(key: K, value: string, index: number): void => {
    setFormValue((prevState: CreateSuggestions) => {
      let newValues: string[] = [...prevState[key] as []]
      newValues[index] = value

      return {
        ...prevState,
        [key]: newValues
      }
    })
  }

  const removeArrayValueAtIndex = <K extends keyof CreateSuggestions>(key: K, index: number): void => {
    setFormValue((prevState: CreateSuggestions) => {
      let newValues: string[] = [...prevState[key] as []]
      newValues.splice(index, 1)

      return {
        ...prevState,
        [key]: newValues
      }
    })
  }

  const setStringValue = <K extends keyof CreateSuggestions>(key: K, value: string): void => {
    setFormValue((prevState: CreateSuggestions) => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  const formSteps = (): ReactElement[] => {
    const submit = async (event: FormEvent): Promise<void> => {
      event.preventDefault()

      const skills: string[] = cleanseArray(formValue.skills)
      const interests: string[] = cleanseArray(formValue.interests)

      if (currentFormStepIndex === 1) {
        if (skills.length < 1) {
          notify('Skills cannot be empty. Please add at least one skill.', 'error')
          return
        }
      }

      if (currentFormStepIndex === 2) {
        if (interests.length < 1) {
          notify('Interests cannot be empty. Please add at least one interest.', 'error')
          return
        }
      }

      if (currentFormStepIndex === currentStepsAmount) {
        await createSuggestionsMutation.mutate({
          userId: authorizationContext.value?.user_id ?? '',
          skills: skills,
          interests: interests,
        })

        return
      }

      setCurrentFormStepIndex((prev: number) => prev + 1)
    }

    return [
      <FormStep
        key={0}
        title={'What are your superpowers?'}
        descriptions={[
          'Tell us what you’re great at, and we’ll help you uncover your passion that aligns with your unique strengths, enabling you to unlock your true potential and thrive in your endeavors.'
        ]}
        submit={submit}
      >
        <MultiInput
          label={'Your skills *'}
          items={formValue.skills}
          placeholder={randomItemFromValues(skillsPlaceholders)}
          setItem={(value: string, index: number) => setArrayValueAtIndex('skills', value, index)}
          removeItem={(index: number) => removeArrayValueAtIndex('skills', index)}
          addButtonPlaceholder={'Add skill'}
        />
      </FormStep>,
      <FormStep
        key={1}
        title={'What sparks your passion?'}
        descriptions={[
          "“The only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle.” — Steve Jobs"
        ]}
        submit={submit}
      >
        <MultiInput
          label={'Your interests *'}
          items={formValue.interests}
          placeholder={randomItemFromValues(interestsPlaceholders)}
          setItem={(value: string, index: number) => setArrayValueAtIndex('interests', value, index)}
          removeItem={(index: number) => removeArrayValueAtIndex('interests', index)}
          addButtonPlaceholder={'Add interest'}
        />
      </FormStep>,
      <FormStep
        key={2}
        title={'Anything else to add?'}
        descriptions={[
          'Add any final thoughts or details here. This space is for any last details or insights you’d like to include that didn’t quite fit elsewhere.'
        ]}
        submit={submit}
      >
        <TextArea
          label={'Notes'}
          value={formValue.notes ?? ''}
          placeholder={randomItemFromValues(notesPlaceholders)}
          setValue={(value) => setStringValue('notes', value)}
        />
      </FormStep>
    ]
  }

  const randomItemFromValues = (values: string[]): string | undefined =>
    values.length !== 0 ? values[Math.floor(Math.random() * values.length)] : undefined

  const isFirstStep = (): boolean => currentFormStepIndex === 1

  const prefillButtonClick = (): void => {
    setFormValue((previousInputs ?? [])[0])
    notify('Form prefilled successfully!', 'success')
  }

  return (
    <div className="m-auto lg:w-[550px] lg:max-w-[550px]">
      {previousFormButton()}

      <FormStepsBar
        step={currentFormStepIndex}
        max={formSteps().length}
      />

      {
        isFirstStep() && ((previousInputs ?? []).length > 0) && (
          <div className="flex flex-col">
            <div className="mt-8 lg:mt-10"/>

            <button
              className="m-auto p-2 border-[1px] border-[#e4e0e1] rounded-[10px] text-[12px] lg:text-[14px] hover:border-[#0035ff] transition"
              onClick={prefillButtonClick}
            >
              {'Prefill with last submission'}
            </button>
          </div>
        )
      }

      <div className="mt-8 lg:mt-10"/>

      {formSteps().map((formStep: ReactElement, index: number) => (
        currentFormStepIndex === index + 1 && formStep
      ))}
    </div>
  )
}

export default CreateSuggestionsForm
