import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import { Step as TStep } from '../types/types'
import { ReactComponent as JourneyIcon } from '../media/journey-icon.svg'
import { ReactComponent as FinishFlagIcon } from '../media/finish-flag-icon.svg'
import Step from './Step'

type stepsProps = {
    values: TStep[]
}

const Steps: React.FC<stepsProps> = ({ values }): ReactElement => (
  <div className="flex flex-col relative without-scrollbar">
    <div className="sm:hidden">
      <div className="mt-2"/>

      <p className="text-[12px] xl:text-[14px] font-[500]">
        {'Steps'}
      </p>
    </div>

    <div className="mt-4 xl:mt-6"/>

    <div className="flex flex-col sm:pl-6 xl:pl-10 relative">
      <div className="flex items-center p-2 m-auto text-center border-[1px] border-[#e4e0e1] rounded-[10px] after:content-[''] after:w-[1px] after:h-[17px] after:bottom-[-16px] after:left-[50%] after:bg-[#e4e0e1] after:z-[1] after:absolute"
        style={{
          boxShadow: '0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
        }}
      >
        <JourneyIcon className="w-[18px] h-[18px]"/>

        <div className="ml-1"/>

        <p className="text-[12px] xl:text-[14px] font-[500]">
          {'Your journey starts here...'}
        </p>
      </div>
    </div>

    {values.map((step: TStep, index: number) => (
      <Step
        key={index}
        index={index}
        value={step}
        opened={index === 0}
        totalStepsCount={values.length}
      />
    ))}

    <div className="mt-4"/>

    <div className="flex flex-col sm:pl-6 xl:pl-10 relative">
      <div className="flex items-center p-2 m-auto text-center border-[1px] border-[#e4e0e1] rounded-[10px] after:content-[''] after:w-[1px] after:h-[17px] after:bottom-[-16px] after:left-[50%] after:bg-[#e4e0e1] after:z-[1] after:absolute after:hidden"
        style={{
          boxShadow: '0 9px 31px #060c240a, 0 2px 5px #060c2408, 0 .5px 1px #060c2405'
        }}
      >
        <FinishFlagIcon className="w-[18px] h-[18px]"/>

        <div className="ml-1"/>

        <p className="text-[12px] xl:text-[14px] font-[500]">
          {'Your adventure ends here… or does it?'}
        </p>
      </div>
    </div>

    <div className="mb-6"/>
  </div>
)

Steps.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      courses: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          headline: PropTypes.string.isRequired,
          rating: PropTypes.number.isRequired,
          subscribers: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired
}

export default Steps
